import { useEffect, useMemo } from "react";
import { useAppActions } from "../../AppActionsProvider";
import { useNewItem } from "../../toolympus/api/useNewItem";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { useSpace } from "../Spaces/SpaceContext";
import { Task, TaskListEntry } from "./typings";
import { apiFetch } from "../../toolympus/api/core";

const getMinOrder = (entries: TaskListEntry[]) => Math.min(...entries.map(x => x.entry_order), 0);
const getMaxOrder = (entries: TaskListEntry[]) => Math.max(...entries.map(x => x.entry_order), 0);

const createTaskSorter = (orders: TaskListEntry[]) => {
  return (t1: Task,t2: Task) => {
    const o1 = orders.find(e => e.task_id === t1._id)?.entry_order || 1e9;
    const o2 = orders.find(e => e.task_id === t2._id)?.entry_order || 1e9;

    return o1 === o2
      ? t1._id < t2._id ? -1 : 1
      : o1 < o2
        ? -1
        : 1;
    
  }
}

const getTaskOrderBefore = (reordered: Task, before: Task, sortedTasks: Task[], orders: TaskListEntry[]): number | null => {
  if(reordered === before) {
    return null;
  }

  const beforeIdx = sortedTasks.indexOf(before);
  let newOrder: number | null = null;
  if(beforeIdx <= 0) {
    newOrder = getMinOrder(orders) - 10;
  } else {
    const preBeforeIdx = beforeIdx - 1;
    const preBefore = sortedTasks[preBeforeIdx];
    
    const beforeOE = orders.find(e => e.task_id === before._id);
    const preBeforeOE = orders.find(e => e.task_id === preBefore._id);
    if(!preBeforeOE || !beforeOE) {
      newOrder = getMinOrder(orders) - 10;
    } else {
      newOrder = preBeforeOE.entry_order + 0.5*(beforeOE.entry_order - preBeforeOE.entry_order);
    }
  }

  return newOrder;
}




export const useProjectTasks = (projectId: string) => {
  const { spaceId } = useSpace();
  const actions = useAppActions();

  const data = useLoadedData<Task[]>(`/api/s/${spaceId}/task?project_id=${projectId}`, [], !!spaceId && !!projectId);

  const orderData = useLoadedData<TaskListEntry[]>(`/api/s/${spaceId}/tasklist/bare/full/project/${projectId}`, [], !!spaceId && !!projectId);
  const currentOrderData = useLoadedData<TaskListEntry[]>(`/api/s/${spaceId}/tasklist/bare/current/project/${projectId}`, [], !!spaceId && !!projectId);

  useEffect(() => {
    const onTaskChanged = (task: Task) => {
      if(task.project_id === projectId) {
        data.setData(x => x.find(t => task._id === t._id)
          ? x.map(t => t._id === task._id ? { ...t, ...task } : t)
          : [...x, { ...task }]);
        if(!data.data.find(t => task._id === t._id)) {
          orderData.reload();
          currentOrderData.reload();
        }
      }
    }
    actions.registerTaskUpdatedHandler(onTaskChanged);
    return () => actions.unregisterTaskUpdatedHandler(onTaskChanged);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const newTask = useNewItem<Partial<Task>, Task>(`/api/s/${spaceId}/task`, { title: "", project_id: projectId }, {
  });

  const [sortedTasks,currentSortedTasks] = useMemo(() => {
    const nonCurrent = data.data
      .filter(t => !currentOrderData.data.find(e => e.task_id === t._id))
      .sort(createTaskSorter(orderData.data));

    const current = data.data
      .filter(t => currentOrderData.data.find(e => e.task_id === t._id))
      .sort(createTaskSorter(currentOrderData.data));

    return [nonCurrent, current];
  }, [data.data, orderData.data, currentOrderData.data]);

  const updateFullTaskOrder = async (taskId: string, newOrder: number) => {
    const tl = await apiFetch<TaskListEntry>(`/api/s/${spaceId}/tasklist`, "put", {
      task_id: taskId,
      entry_order: newOrder,
      list_type: "full",
      attached_to_kind: "project",
      attached_to_id: projectId,
    });
    orderData.setData(x => x.find(e => e.task_id === taskId) ? x.map(e => e.task_id === taskId ? tl : e) : [...x, tl]);
    const inCurrent = currentOrderData.data.find(e => e.task_id === taskId)
    if(inCurrent) {
      await apiFetch<TaskListEntry>(`/api/s/${spaceId}/tasklist`, "put", {
        task_id: taskId,
        entry_order: inCurrent.entry_order,
        list_type: "current",
        attached_to_kind: "project",
        attached_to_id: projectId,
        remove: true,
      });
      currentOrderData.setData(x => x.filter(e => e.task_id !== taskId));
    }
    return tl;
  }

  const reorderFullTaskBefore = (reordered: Task, before: Task) => {
    if(reordered === before) {
      return;
    }

    const newOrder = getTaskOrderBefore(reordered, before, sortedTasks, orderData.data);

    if(newOrder !== null) {
      updateFullTaskOrder(reordered._id, newOrder);
    }
  }

  const reorderFullTo = (reordered: Task, position: "top" | "bottom") => {
    const newOrder = position === "top"
      ? getMinOrder(orderData.data) - 10
      : getMaxOrder(orderData.data) + 10;
      updateFullTaskOrder(reordered._id, newOrder);
  }

  const updateCurrentTaskOrder = async (taskId: string, newOrder: number) => {
    const tl = await apiFetch<TaskListEntry>(`/api/s/${spaceId}/tasklist`, "put", {
      task_id: taskId,
      entry_order: newOrder,
      list_type: "current",
      attached_to_kind: "project",
      attached_to_id: projectId,
    });
    currentOrderData.setData(x => x.find(e => e.task_id === taskId) ? x.map(e => e.task_id === taskId ? tl : e) : [...x, tl]);
    return tl;
  }

  const reorderCurrentTaskBefore = (reordered: Task, before: Task | null) => {
    if(!reordered) {
      return null;
    }

    let newOrder: number | null = null;
    if(!before) {
      newOrder = getMaxOrder(currentOrderData.data) + 10;
    } else {
      const beforeIdx = currentSortedTasks.indexOf(before);
      if(beforeIdx < 0) {
        newOrder = getMaxOrder(currentOrderData.data) + 10;
      } else {
        newOrder = getTaskOrderBefore(reordered, before, currentSortedTasks, currentOrderData.data);
      }
    }

    if(newOrder !== null) {
      updateCurrentTaskOrder(reordered._id, newOrder);
    }
  }

  const reorderCurrentTo = (reordered: Task, position: "top" | "bottom") => {
    const newOrder = position === "top"
      ? getMinOrder(currentOrderData.data) - 10
      : getMaxOrder(currentOrderData.data) + 10;
    updateCurrentTaskOrder(reordered._id, newOrder);
  }


  return {
    ...data,
    data: sortedTasks,
    currentTasks: currentSortedTasks,
    newTask: {
      ...newTask,
      save: async (x?: Partial<Task>) => {
        const fullData = { ...newTask.item, ...x };
        const titleLines = (fullData.title || "").split("\n").map(s => s.trim()).filter(s => !!s);
        if(titleLines.length > 1) {
          let resultTask = fullData;
          for (let i = 0; i < titleLines.length; i++) {
            const line = titleLines[i];
            const t = await apiFetch<Task>(`/api/s/${spaceId}/task`, "post", { ...fullData, title: line });
            data.setData(x => [t, ...x]);
            await updateFullTaskOrder(t._id, getMinOrder(orderData.data) - 10*(i+1));
            resultTask = t;
            newTask.cancel();
          }
          return resultTask as Task;
        } else {
          const t = await newTask.save(x);
          data.setData(x => [t, ...x]);
          await updateFullTaskOrder(t._id, getMinOrder(orderData.data) - 10);
          return t;
        }
      }
    },
    reorderFull: {
      reorderBefore: reorderFullTaskBefore,
      reorderTo: reorderFullTo,
    },
    reorderCurrent: {
      reorderBefore: reorderCurrentTaskBefore,
      reorderTo: reorderCurrentTo,
    }
  }
}
